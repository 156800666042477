import React, { useEffect } from "react"
import PropTypes from "prop-types"

import "../../css/tailwind.css"
import "../../css/base.css"
import "../../css/fonts.css"
import "focus-visible/dist/focus-visible.min"

const Layout = ({ children, className }) => {
  // easter egg in console
  useEffect(() => {
    console.log(
      "%ccheese! 📸",
      "font-size: 32px; font-family: 'Monument Extended'"
    )
  }, [])

  return <main className={className}>{children}</main>
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Layout
